import { useEffect } from "react";
import Spline from "@splinetool/react-spline";

function Biling() {
  useEffect(() => {
    document.title = "Biling | Prokits Networks";
  }, []);

  return (
    <div>
      <div className="container">
        <Spline scene="https://prod.spline.design/IkaM8VFGB5Mff2um/scene.splinecode" />
        <h1>Billing</h1>
        <div className="card_container">
          <div className="plan">
            <h1>Economy Plan</h1>
            <p>Best Plan for everyday use.</p>
            <ul>
              <li>Unlimited Data</li>
              <li>Upto 100mbps</li>
              <li>Unlimited Devices</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biling;
